@import "../../assets/variables.module.scss";

.card-slider {
    display: flex;
    flex-direction: column;
    background: transparent;
}

.card-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}


.indicator {
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    width: 50px;
    padding: 20px;
}

.footer {
    display: flex;
    flex-direction: column;

    button {
        margin: auto;
        min-width: 0;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        border: none;
        padding: 10px 20px;
        background: $btn-primary;
        color: $light-theme;
        font-size: $smaller-font;

        &:focus {
            outline: none;
            border: none;
        }
    }
}

.circle {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background: $stroke-dark;
}

.filled {
    background: $rover-blue;
}