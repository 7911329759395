@import "../../assets/variables.module.scss";

.account-info {
    display: flex;
    flex-direction: column;

    section {
        margin: 20px;
        padding: 10px 0px 15px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
        border-radius: 4px;

        div {
            display: flex;
        }

        .title {
            padding: 15px 10px 5px;
            border-bottom: 2px solid $stroke-light;
            font-weight: bold;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0.15px;
        }

        .row {
            margin: 15px;
            display: flex;
            justify-content: space-between;
        }
    }

    /* unvisited link */
    a:link {
        color: $txt-main;
    }
    
    /* visited link */
    a:visited {
        color: $txt-main;
    }
    
    /* mouse over link */
    a:hover {
        color: $btn-primary;
    }
    
    /* selected link */
    a:active {
        color: $rover-blue;
    }
}