//themes
$dark-theme: rgb(17, 18, 22);
$light-theme: rgb(253, 253, 253);
$accent-grey: #F4F4F4;

// colors
$rover-blue: rgba(109, 197, 219, 1);
$muted-blue: rgba(109, 197, 219, 0.25);
$btn-primary: rgba(109, 219, 186, 1);
$btn-secndry: rgba(109, 219, 186, 0.5);
$btn-disabled: rgb(229, 229, 229);
$txt-main: rgb(75, 77, 82);
$txt-light: rgb(148, 153, 163);
$stroke-dark: rgb(169, 174, 184);
$stroke-mid: #A9AEB8;
$stroke-light: rgb(220, 220, 220);

// font 
$font-family: 'Roboto', sans-serif !important;
// font size
$large-font: 28px;
$medium-font: 22px;
$small-font: 18px;
$smaller-font: 14px;

:export {
    fontFamily: $font-family;
    lightTheme: $light-theme;
    roverBlue: $rover-blue;
    mutedBlue: $muted-blue;
    darkStroke: $stroke-dark;
    lightStroke: $stroke-light;
    btnPrimary: $btn-primary;
    txtMain: $txt-main;
    txtLight: $txt-light;
}