@import './variables.module.scss';

html, body, #root {
  height: 100%;
  background-color: $muted-blue;
}

// common styles
button {
    display: block;
    min-width: 50%;
    margin: auto;
    text-transform: uppercase;
    color: $muted-blue;
    background: $dark-theme;
    font-size: $medium-font;
    font-weight: 600;
    border: 2px solid $stroke-dark;
    border-radius: 10px;
    padding: 8px;
    letter-spacing: 2px;
    cursor: pointer;
  
    &:hover {
      color: $light-theme;
      border-color: $light-theme;
    }
  }
  
  .clickable {
    color: $light-theme;
    cursor: pointer;
  
    &:hover {
      color: $muted-blue;
      text-decoration: underline;
    }
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid $rover-blue;
    -webkit-text-fill-color: $light-theme;
    font-size: $medium-font;
    transition: background-color 5000s ease-in-out 0s;
  }