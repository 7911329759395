@import "../../assets/variables.module.scss";

[data-test^="sign-in-header-section"] {
    display: none;
}

[data-test^="sign-out-button"] {
    background-color: $btn-primary !important;
}

[class^="Form__formContainer___"] > *,
input,
button {
  font-family: $font-family;
}

input {
  &::placeholder {
    font-size: 18px;
  }
}

#header {
    display: flex;
    flex-direction: row;
    padding: 20px;
  
    img {
        width: 20%;
        height: auto;
        min-width: 80px;
        max-width: 192px;
        pointer-events: none;
        background-color: $light-theme;
        border-radius: 30%;
    }
  }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $txt-main;
}

